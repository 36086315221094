import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from 'react-bootstrap';
import Select from "react-select";
import { navigate } from "@reach/router"
import { Link } from "@StarberryUtils";
import $ from "jquery";
import SearchInputBox from '../predictive-search/search-inputbox';
import { useLocation } from "@reach/router"
import _ from "lodash"

const SearchResultsFilterResiSales = (props) => {
    //
    const refineFilterResults = (e) => {
        $(".filter-toggle").toggleClass("active");
        $(".filters-form").toggleClass("active-filter");
    }
    //

    // Sales & Rent
    const onClickBuy = (e) => {
        navigate(`/property/for-sale/in-london/`)
    }

    const { pathname } = useLocation()

    let newpath = ''

    const submitSearchviaSold =(e) => {
      if(pathname.search("available") < 0 ){
         setincludeSoldValue(false)
        if (!pathname.endsWith('/'))
          newpath = pathname+"/available/"
        else
          newpath = pathname+"available/"
      } else {
         setincludeSoldValue(true)
        newpath = pathname.replace("available","")
          //remove multiple slashes
        newpath = newpath.replace(/([^:]\/)\/+/g, "$1");
      }
      navigate(newpath)
    }

    const onClickRent = (e) => {
        navigate(`/property/to-rent/in-london/`)
    }
    // Sales & Rent

    // Property Min Price
    const [selectedMinPriceValue, setSelectedMinPriceValue] = useState(0);
    const priceminRange = [
    {
      value: "0",
      label: "No Min"
    },
    {
      value: "200000",
      label: "£200,000"
    },
    {
      value: "225000",
      label: "£225,000"
    },
    {
      value: "250000",
      label: "£250,000"
    },
    {
      value: "275000",
      label: "£275,000"
    },
    {
      value: "300000",
      label: "£300,000"
    },
    {
      value: "325000",
      label: "£325,000"
    },
    {
      value: "350000",
      label: "£350,000"
    },
    {
      value: "375000",
      label: "£375,000"
    },
    {
      value: "400000",
      label: "£400,000"
    },
    {
      value: "425000",
      label: "£425,000"
    },
    {
      value: "450000",
      label: "£450,000"
    },
    {
      value: "475000",
      label: "£475,000"
    },
    {
      value: "500000",
      label: "£500,000"
    },
    {
      value: "525000",
      label: "£525,000"
    },
    {
      value: "550000",
      label: "£550,000"
    },
    {
      value: "575000",
      label: "£575,000"
    },
    {
      value: "600000",
      label: "£600,000"
    },
    {
      value: "625000",
      label: "£625,000"
    },
    {
      value: "650000",
      label: "£650,000"
    },
    {
      value: "675000",
      label: "£675,000"
    },
    {
      value: "700000",
      label: "£700,000"
    },
    {
      value: "725000",
      label: "£725,000"
    },
    {
      value: "750000",
      label: "£750,000"
    },
    {
      value: "775000",
      label: "£775,000"
    },
    {
      value: "800000",
      label: "£800,000"
    },
    {
      value: "825000",
      label: "£825,000"
    },
    {
      value: "850000",
      label: "£850,000"
    },
    {
      value: "875000",
      label: "£875,000"
    },
    {
      value: "900000",
      label: "£900,000"
    },
    {
      value: "1000000",
      label: "£1,000,000"
    },
    {
      value: "1100000",
      label: "£1,100,000"
    },
    {
      value: "1200000",
      label: "£1,200,000"
    },
    {
      value: "1300000",
      label: "£1,300,000"
    },
    {
      value: "1400000",
      label: "£1,400,000"
    },
    {
      value: "1500000",
      label: "£1,500,000"
    },
    {
      value: "1600000",
      label: "£1,600,000"
    },
    {
      value: "1700000",
      label: "£1,700,000"
    },
    {
      value: "1800000",
      label: "£1,800,000"
    },
    {
      value: "1900000",
      label: "£1,900,000"
    },
    {
      value: "2000000",
      label: "£2,000,000"
    },
    {
      value: "2250000",
      label: "£2,250,000"
    },
    {
      value: "2500000",
      label: "£2,500,000"
    },
    {
      value: "2750000",
      label: "£2,750,000"
    },
    {
      value: "3000000",
      label: "£3,000,000"
    },
    {
      value: "3250000",
      label: "£3,250,000"
    },
    {
      value: "3500000",
      label: "£3,500,000"
    },
    {
      value: "3750000",
      label: "£3,750,000"
    },
    {
      value: "4000000",
      label: "£4,000,000"
    },
    {
      value: "4250000",
      label: "£4,250,000"
    },
    {
      value: "4500000",
      label: "£4,500,000"
    }
    ]
    // Property Min Price

    // Property Max Price
    const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState(0);

    const bedroomlist = [
      { value: "0", label: "No Min" },
      { value: '1', label: '1 +' },
      { value: '2', label: '2 +' },
      { value: '3', label: '3 +' },
      { value: '4', label: '4 +' },
      { value: '5', label: '5 +' },
      { value: '6', label: '6 +' }
  ]


  const propertytypelist = [
      { value: "", label: "All Properties" },
      { value: "apartment", label: "Flat/Apartment" },
      { value: "house", label: "All Houses" },
      { value: "semi-detached-house", label: "House (Semi-detached)" },
      { value: "detached-house", label: "House (Detached)" },
      { value: "bungalow", label: "Bungalows" },
      { value: "new-home", label: "New Homes" },
      { value: "buy-to-let-investments", label: "Buy to Let Investments" },
      { value: "terrace", label: "Terrace" },
  ]

    const pricemaxRange = [
    {
      value: "0",
      label: "No Max"
    },
    {
      value: "4500000",
      label: "£4,500,000 +"
    },
    {
      value: "4250000",
      label: "£4,250,000"
    },
    {
      value: "4000000",
      label: "£4,000,000"
    },
    {
      value: "3750000",
      label: "£3,750,000"
    },
    {
      value: "3500000",
      label: "£3,500,000"
    },
    {
      value: "3250000",
      label: "£3,250,000"
    },
    {
      value: "3000000",
      label: "£3,000,000"
    },
    {
      value: "2750000",
      label: "£2,750,000"
    },
    {
      value: "2500000",
      label: "£2,500,000"
    },
    {
      value: "2250000",
      label: "£2,250,000"
    },
    {
      value: "2000000",
      label: "£2,000,000"
    },
    {
      value: "1900000",
      label: "£1,900,000"
    },
    {
      value: "1800000",
      label: "£1,800,000"
    },
    {
      value: "1700000",
      label: "£1,700,000"
    },
    {
      value: "1600000",
      label: "£1,600,000"
    },
    {
      value: "1500000",
      label: "£1,500,000"
    },
    {
      value: "1400000",
      label: "£1,400,000"
    },
    {
      value: "1300000",
      label: "£1,300,000"
    },
    {
      value: "1200000",
      label: "£1,200,000"
    },
    {
      value: "1100000",
      label: "£1,100,000"
    },
    {
      value: "1000000",
      label: "£1,000,000"
    },
    {
      value: "900000",
      label: "£900,000"
    },
    {
      value: "875000",
      label: "£875,000"
    },
    {
      value: "850000",
      label: "£850,000"
    },
    {
      value: "825000",
      label: "£825,000"
    },
    {
      value: "800000",
      label: "£800,000"
    },
    {
      value: "775000",
      label: "£775,000"
    },
    {
      value: "750000",
      label: "£750,000"
    },
    {
      value: "725000",
      label: "£725,000"
    },
    {
      value: "700000",
      label: "£700,000"
    },
    {
      value: "675000",
      label: "£675,000"
    },
    {
      value: "650000",
      label: "£650,000"
    },
    {
      value: "625000",
      label: "£625,000"
    },
    {
      value: "600000",
      label: "£600,000"
    },
    {
      value: "575000",
      label: "£575,000"
    },
    {
      value: "550000",
      label: "£550,000"
    },
    {
      value: "525000",
      label: "£525,000"
    },
    {
      value: "500000",
      label: "£500,000"
    },
    {
      value: "475000",
      label: "£475,000"
    },
    {
      value: "450000",
      label: "£450,000"
    },
    {
      value: "425000",
      label: "£425,000"
    },
    {
      value: "400000",
      label: "£400,000"
    },
    {
      value: "375000",
      label: "£375,000"
    },
    {
      value: "350000",
      label: "£350,000"
    },
    {
      value: "325000",
      label: "£325,000"
    },
    {
      value: "300000",
      label: "£300,000"
    },
    {
      value: "275000",
      label: "£275,000"
    },
    {
      value: "250000",
      label: "£250,000"
    },
    {
      value: "225000",
      label: "£225,000"
    },
    {
      value: "200000",
      label: "£200,000"
    }
    ]
    // Property Max Price

    // Min price
    const onChangeMinPrice = (e) => {
        setSelectedMinPriceValue(e.value);
    }
    // Min price

    // Max Price
    const onChangeMaxPrice = (e) => {
        setSelectedMaxPriceValue(e.value);
    }
    // Max Price

    // Filters
    const [openFilter, setOpenFilter] = React.useState(false);
    const fitlerDisplay = (e) => {
      setOpenFilter(!openFilter)
    }
    // Filters

    //
    var minPrice = [];
    var maxPrice = pricemaxRange.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

    priceminRange.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPrice.push(items));
    // pricemaxRange.map(items => parseInt(items.value) > parseInt(selectedMinPriceValue) && maxPrice.push(items));

    if (minPrice.length > 0) {
    } else {
      minPrice.push(priceminRange[0])
    }

    if (maxPrice.length > 0) {
    } else {
      maxPrice.push(pricemaxRange[0])
    }
    //

    // Include sold
    const [includeSoldValue, setincludeSoldValue] = useState(true);
    var mustIncludeVal = includeSoldValue
    // Include sold

    // Outside space
    const [outsideSpaceValue, setoutsideSpaceValue] = useState(false);
    var outsideSpaceVal = outsideSpaceValue
    // Outside space

    // Chain free
    const [chainFreeValue, setchainFreeValue] = useState(false);
    var chainFreeVal = chainFreeValue
    // Chain free

    // Parking
    const [parkingValue, setparkingValue] = useState(false);
    var parkingVal = parkingValue
    // Parking


    const [areaVal, setAreaVal] = useState('');
    const [minpriceVal, setMinPriceVal] = useState('');
    const [maxpriceVal, setMaxPriceVal] = useState('');
    const [propertyval, setPropertyVal] = useState('');
    const [bedVal, setBedVal] = useState('');
    var pageVal = ""
    var sortVal = ""
    var sortVal_filt = ""

    // Submit search
    const submitSearch = (e) => {
        //
        var areaValue = $(".searchboxresults .react-autosuggest__input").val().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").toLowerCase();
        var buildingValue = propertyval ? propertyval : $(".building-dropdown input[type='hidden']").val();
        var bedroomValue = bedVal ? bedVal : $(".bedroom-dropdown input[type='hidden']").val();
        // var sortbyValue = $(".sortby-dropdown input[type='hidden']").val();
        // var sortbyValue = $(".sortby-dropdown .select-opt__single-value").text().replace(/ /g, '-').toLowerCase();

        // let sortybyurl = ""
        // if (sortbyValue === "highest-price-first") {
        //   sortybyurl = "sortby-price-desc"
        // } else if (sortbyValue === "lowest-price-first") {
        //   sortybyurl = "sortby-price-asc"
        // } else if (sortbyValue === "most-recent-first") {
        //   sortybyurl = "sortby-most-recent"
        // }




        let priceUrl = ""

        if(maxpriceVal || minpriceVal) {
          if(minpriceVal && maxpriceVal && minpriceVal != "0" && maxpriceVal != "0") {
            priceUrl = "between-" + minpriceVal + "-and-" + maxpriceVal
          } else
          if((minpriceVal == '' || minpriceVal == "0") && maxpriceVal && maxpriceVal != "0") {
            priceUrl = "under-" + maxpriceVal
          }
          else if(minpriceVal && (maxpriceVal == '' || maxpriceVal == "0") && minpriceVal != "0") {
            priceUrl = "over-" + minpriceVal
          }

        }


        // if(maxpriceVal || minpriceVal) {
        //   if(minpriceVal == '' && maxpriceVal) {
        //     priceUrl = "under-" + maxpriceVal
        //   }
        //   else if(minpriceVal && maxpriceVal == '') {
        //     priceUrl = "over-" + minpriceVal
        //   }
        //   else if(minpriceVal && maxpriceVal) {
        //     priceUrl = "between-" + minpriceVal + "-and-" + maxpriceVal
        //   }

        // }
        // else {
        //   if ((selectedMinPriceValue !== undefined ) && (selectedMaxPriceValue !== undefined) && (selectedMinPriceValue > 0 ) && (selectedMaxPriceValue > 0)) {
        //     priceUrl = "between-" + selectedMinPriceValue + "-and-" + selectedMaxPriceValue
        //   } else if ((selectedMinPriceValue !== undefined) && (selectedMinPriceValue > 0) ) {
        //     priceUrl = "over-" + selectedMinPriceValue
        //   } else if ((selectedMaxPriceValue !== undefined) && (selectedMaxPriceValue > 0)) {
        //     priceUrl = "under-" + selectedMaxPriceValue
        //   } else { priceUrl = "" }
        // }



        var mustIncludeval2 = ""
        if (outsideSpaceVal === true && chainFreeVal === true && parkingVal === true) {
            mustIncludeval2 = "with-outside-space-and-chain-free-and-parking"
        }
        else if (outsideSpaceVal === true && chainFreeVal === true && parkingVal === false) {
            mustIncludeval2 = "with-outside-space-and-chain-free"
        }
        else if (outsideSpaceVal === true && chainFreeVal === false && parkingVal === true) {
            mustIncludeval2 = "with-outside-space-and-parking"
        }
        else if (outsideSpaceVal === false && chainFreeVal === true && parkingVal === true) {
            mustIncludeval2 = "with-chain-free-and-parking"
        }
        else if(outsideSpaceVal === true && chainFreeVal === false && parkingVal === false) {
            mustIncludeval2 = "with-outside-space"
        }
        else if(outsideSpaceVal === false && chainFreeVal === true && parkingVal === false) {
            mustIncludeval2 = "with-chain-free"
        }
        else if(outsideSpaceVal === false && chainFreeVal === false && parkingVal === true) {
            mustIncludeval2 = "with-parking"
        }
        //

        var searchFilterResults = (
            (areaValue ? "/property/for-sale/in-"+areaValue +"/" : "/property/for-sale/in-london/") +
            (buildingValue ? "type-"+buildingValue+"/" : '') +
            (bedroomValue && bedroomValue != "0" ? bedroomValue+"-and-more-bedrooms/" : '') +
            (priceUrl ? priceUrl+"/" : '') +
            (mustIncludeval2 ? mustIncludeval2+"/" : '') +
            (mustIncludeVal === false ? "available/" : '')
            // (sortybyurl ? sortybyurl : '')
        );

        navigate(searchFilterResults)
    }
    // Submit search


    //

    useEffect(() => {

      const url = typeof window !== 'undefined' ? window.location.href : ''
      let pathUri = url.split("/")
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
            continue
        }

        // Property type
        if (pathUri[vi].indexOf("type-") >= 0) {
          setPropertyVal(pathUri[vi].replace("type-", ""))
        }
        // Property type

        // Area
        if ((pathUri[vi].indexOf("in-") == 0) && (pathUri[vi].indexOf("in-london") == -1)) {
          setAreaVal(_.upperFirst(pathUri[vi].replace("in-", "")))
        }
        // Area

        // Price
          if (
            pathUri[vi].indexOf("between-") >= 0 ||
            pathUri[vi].indexOf("over-") >= 0 ||
            pathUri[vi].indexOf("under-") >= 0
        ) {
            let priceFilt1 = pathUri[vi].split("over-")
            if (priceFilt1[1]) {
              setMinPriceVal(priceFilt1[1])
            }
            let priceFilt2 = pathUri[vi].split("under-")
            if (priceFilt2[1]) {
              setMaxPriceVal(priceFilt2[1])
            }
            let priceFilt3 = pathUri[vi].split("between-")
            if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
              setMinPriceVal(priceFilt4[0])
              setMaxPriceVal(priceFilt4[1])
            }
        }
        // Price

        // Bedrooms
          if (pathUri[vi].indexOf("-and-more-") >= 0) {
            setBedVal(pathUri[vi].replace("-and-more-bedrooms", ""))
        }
        // Bedrooms

        // Include

        if (pathUri[vi].indexOf("-outside-space") >= 0) {
          setoutsideSpaceValue(true)
        }
        if (pathUri[vi].indexOf("-chain-free") >= 0) {
          setchainFreeValue(true)
        }
        if (pathUri[vi].indexOf("-parking") >= 0) {
          setparkingValue(true)
        }

        if (pathUri[vi].indexOf("available") >= 0) {
          setincludeSoldValue(false)
        }

        // Include



      }
    }, []);
    //

    return (
        <>
            <Link className={"filter-toggle d-flex justify-content-between align-items-center d-xl-none text-uppercase"} to="#" onClick={refineFilterResults}>
                <span>refine your results</span>
                <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.98403 8.00005L0.960028 15.04C0.752028 15.248 0.400028 15.248 0.176028 15.04C-0.0319716 14.832 -0.0319716 14.48 0.176028 14.256L6.43203 8.00005L0.160028 1.72805C-0.0479717 1.52005 -0.0479717 1.16805 0.160028 0.944047C0.368028 0.736047 0.720028 0.736047 0.944028 0.944047L7.98403 8.00005Z" fill="#ffffff"/>
                </svg>
            </Link>
            <Form  className={"filters-form d-xl-flex justify-content-between"}>
                <Form.Group className="radios-wrp">
                <div className="radio-boxes d-flex">
                    <a href="javascript:void(0)" className="property_filter_btn active" onClick={onClickBuy}>Buy</a>
                    <a href="javascript:void(0)" className="property_filter_btn" onClick={onClickRent}>Rent</a>
                </div>
                </Form.Group>
                <div className="searchboxresults d-flex align-items-center">
                    <SearchInputBox handleChange={(val)=> setAreaVal(val)} value={areaVal}/>
                </div>
                <Form.Group className="price-wrap d-flex justify-content-between align-items-center">
                    <div className="price-box">
                        <div className="select-group minprice-dropdown">
                            <Select
                                placeholder={minpriceVal ? minpriceVal : "Min Price" }
                                // placeholder="Min Price"
                                options={parseInt(selectedMaxPriceValue) === 0 ? priceminRange : minPrice}
                                value={priceminRange.find(obj => obj.value === minpriceVal) || priceminRange.find(obj => obj.value === minpriceVal)}
                                onChange={e => {onChangeMinPrice(e);setMinPriceVal(e.value)}}
                                classNamePrefix={"select-opt"}
                                isSearchable={false}
                                components={{ DropdownIndicator:() => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator:() => null }}
                            />
                        </div>
                    </div>
                    <div className="price-box">
                        <div className="select-group maxprice-dropdown">
                            <Select
                                placeholder={maxpriceVal ? maxpriceVal : "Max Price" }
                                // placeholder="Max Price"
                                options={parseInt(selectedMinPriceValue) === 0 ? pricemaxRange : maxPrice}
                                value={pricemaxRange.find(obj => obj.value === selectedMaxPriceValue) || pricemaxRange.find(obj => obj.value === maxpriceVal)}
                                onChange={e => {onChangeMaxPrice(e);setMaxPriceVal(e.value)}}
                                classNamePrefix={"select-opt"}
                                isSearchable={false}
                                components={{ DropdownIndicator:() => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator:() => null }}
                            />
                        </div>
                    </div>
                </Form.Group>
                <Form.Group className="bed-wrap select-grp-opt d-flex align-items-center">
                    <div className="select-group slct-room bedroom-dropdown">
                    <Select
                        name="bedrooms"
                        options={bedroomlist}
                        value={bedroomlist.find(obj => obj.value === bedVal)}
                        placeholder={bedVal ? bedVal+" +" : "Bedrooms" }
                        classNamePrefix={"select-opt"}
                        onChange={e => setBedVal(e.value)}
                        isSearchable={false}
                        components={{ DropdownIndicator:() => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator:() => null }}
                    />
                    </div>
                </Form.Group>
                <Form.Group className="select-grp-opt d-flex align-items-center">
                    <div className="select-group building-dropdown">
                        <Select
                            name="building"
                            options={propertytypelist}
                            value={propertytypelist.find(obj => obj.value === propertyval)}
                            placeholder={propertyval ? propertyval+" +" : "Property Type" }
                            classNamePrefix={"select-opt"}
                            isSearchable={false}
                            onChange={e => setPropertyVal(e.value)}
                            components={{ DropdownIndicator:() => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator:() => null }}
                        />
                    </div>
                </Form.Group>

                <Form.Group className="select-grp-opt fil-opt d-flex align-items-center">
                    <a className="filter-dropdown" href="javascript:void(0)" onClick={fitlerDisplay}><span>Filters </span> <i class="icon-arrow-dropdown"></i></a>
                    <div className={`dropdown-exp ${openFilter ? 'active' :''}`}>
                        <div key={`custom-inline-`} className="mb-3">
                            <Form.Group className="mb-0 must-include-check must_include_sold" controlId="include-sold">
                                <Form.Check
                                    type="checkbox"
                                    label={"Include Sold"}
                                    checked={includeSoldValue}
                                    onChange={submitSearchviaSold}
                                />
                            </Form.Group>
                            <Form.Group className="mb-0 must-include-check must_include_sold_mob" controlId="include-sold-mob">
                                <Form.Check
                                    type="checkbox"
                                    label={"Include Sold"}
                                    checked={includeSoldValue}
                                    onChange={submitSearchviaSold}
                                />
                            </Form.Group>
                            <div className="must-include">Must Include</div>
                            <ul className="offer-list d-flex flex-wrap">
                                <li>
                                    <Form.Group className="mb-0 must-include-check" controlId="outside-space">
                                        <Form.Check
                                            type="checkbox"
                                            label={"Outside Space"}
                                            checked={outsideSpaceValue}
                                            onChange={() => setoutsideSpaceValue(!outsideSpaceValue)}
                                        />
                                    </Form.Group>
                                </li>
                                <li>
                                    <Form.Group className="mb-0 must-include-check" controlId="chain-free">
                                        <Form.Check
                                            type="checkbox"
                                            label={"Chain free"}
                                            checked={chainFreeValue}
                                            onChange={() => setchainFreeValue(!chainFreeValue)}
                                        />
                                    </Form.Group>
                                </li>
                                <li>
                                    <Form.Group className="mb-0 must-include-check" controlId="parking">
                                        <Form.Check
                                            type="checkbox"
                                            label={"Parking"}
                                            checked={parkingValue}
                                            onChange={() => setparkingValue(!parkingValue)}
                                        />
                                    </Form.Group>
                                </li>
                            </ul>
                            <div className="search-clear-filter">
                            <a href="/property/for-sale/in-london/">Clear Filter</a>
                            </div>
                        </div>
                    </div>
                </Form.Group>

                <a href="javascript:void(0)" onClick={submitSearch} className="btn submitSearch d-flex align-items-center" loadMoreStop="false">Submit</a>
            </Form>
        </>
    )
}

export default SearchResultsFilterResiSales
